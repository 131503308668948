// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Rubik', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$border-color: rgba(0,0,0, .07);
$card-border-color: rgba(0,0,0, .07);

$border-radius: 8px;
$card-border-radius: 8px;

// Colors
//$blue: #3490dc;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66d9b;
//$red: #e3342f;
//$orange: #f6993f;
//$yellow: #ffed4a;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;
$primary: #fe8901;
$secondary: #16815a;
